@import '__importable.scss';
.button {
	@media screen and (max-width: $media-lg) {
		position: static;
		height: auto;
	}
}
.container {
	overflow-y: scroll;
	a {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&[data-microcart-open="true"] {
		@media screen and (hover: hover) {
			background-color: unset;
		}

		&:active {
			background-color: unset;
			border-color: transparent;
		}
	}
}
.scrollable {
	overflow-y: auto;
}
.microcart-container {
	background-color: $gray-0;
	border: 1px solid $gray-2;
	box-shadow: 0 0.8rem 0.8rem 0 #00000040;
	padding: 0 1.6rem 1.6rem 1.6rem;
	border-radius: 0.8rem;
	flex-direction: column;
	display: flex;
	max-height: 428px;
}
.microcart {
	position: absolute;
	top: 45px;
	right: 0;
	padding-inline: 0.8rem;
	height: auto;
	width: 100%;

	@media screen and (min-width: $media-md) {
		width: 375px;
		top: 55px;
		right: 0;
		&--is-filter {
			top: 12rem !important;
			right: -0.4rem !important;
		}
	}
	@media screen and (min-width: $media-md-max) {
		width: 358px;
		height: 418px;
		top: 55px;
		right: 0;
		&--is-filter {
			top: 12rem !important;
			right: -0.4rem !important;
		}
	}

	@media screen and (hover: hover) {
		cursor: default;
	}
}

.header {
	position: sticky;
	top: 0;
	background-color: $gray-0;
	z-index: $zindex-1;
	padding-block: 0.8rem;

	> [class*="Caption"] {
		margin-right: auto;
	}
}

.caret {
	position: absolute;
	top: -9px;
	right: 27px;
	height: 16px;
	width: 16px;
	background-color: $gray-0;
	border-top: 1px solid $gray-2;
	border-left: 1px solid $gray-2;
	transform: rotate(45deg);

	@media screen and (min-width: $media-md) {
		right: 16px;
	}
}

.topFrameTitle {
	font-family: var(--platform);
}
